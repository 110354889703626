import React, { useState, useEffect } from "react"
import "../CSS/Chat.css"
// import { Link, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { FcGoogle } from "react-icons/fc";
import { BsHourglassSplit, BsPinAngle } from 'react-icons/bs'
import { retrieveThreadsApi } from './ApiConfiguration';

function Chat() {
  // var convid = '';
  const [post, getPost] = useState([]);
  // const [searchParams, setSearchParams] = useSearchParams();

  const fetchPost = () => {
    fetch(retrieveThreadsApi).then((res) => res.json()).then((res) => {
      console.log("Respnose form api:", res.threads);
      getPost(res.threads);
    })
  }

  useEffect(() => {
    fetchPost()
  }, [post])

  const logo = localStorage.getItem('LoginData value')
  // const secondaryColor = localStorage.getItem('secondaryColor')
  // console.log("Conversation Id from URL:", searchParams.get("conversationId"));

  return (
    <>
      <div className='chatmain'>
        <div className='logo-search'>
          <div className='company-logo'> <img src={logo} alt='brand_logo' /> </div>
        </div>

        <div className='chat-message-list'>
          {
            post.map((item, i) => {
              var userName = item.displayName.toLowerCase();
              var splitUserName = userName.split(' ')
              var userProfileImage = splitUserName[0].charAt(0).toUpperCase()

              console.log(userProfileImage);
              console.log(userName);
              console.log("Store Name:", item.storeName);
              // convid = item.conversationId;

              return <div key={i}>

                <Link to={{ pathname: '/userchat', search: `?conversationId=${item.conversationId}&state=${item.state}` }}
                  style={{ textDecoration: "none", textTransform: "capitalize" }}>

                  <div style={{ display: "flex", justifyContent: "space-between", padding: "10px", color: "black" }} key={i}>
                    <div className="d-flex gap-1" style={{ alignItems: "center" }}>
                      {
                        item.state === "Bot" ? <div className="profilePicture"> {userProfileImage} </div> : item.state === "Live Agent" ? <div className="profilePicture2"> {userProfileImage} </div> : <div className="profilePicture3"> {userProfileImage} </div>
                      }
                      <div>
                      {item.displayName}
                      {item.state === "Queued" ? <div style={{ fontSize: "13px", color: "red" }}>  Requested   <BsHourglassSplit /> ( {item.lastUpdated} )</div> : null}
                    </div>
                    </div>
                    <div> <FcGoogle style={{ alignItems: "center" }} /> </div>
                  </div>

                </Link>

                <div className="time_lastMessage">
                  <div className="lastMessage"> {item.lastMessageText} </div>
                  <div className="time"> {item.lastUpdated} </div>
                </div>
                {
                  !item.storeName ? <div style={{ fontSize: "14px", marginLeft: "15px" }} > <BsPinAngle /> &nbsp;No store available  </div> : <div style={{ fontSize: "14px", marginLeft: "15px" }} > <BsPinAngle /> {item.storeName}</div>
                }
                <hr />
              </div>
            })}
        </div>
      </div>
    </>
  )
}

export default Chat