import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"; import "../CSS/Login.css"
import axios from 'axios';
import { Button } from "react-bootstrap"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loginApi, sessionApi } from "./ApiConfiguration";

function Login() {

  const history = useNavigate();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [token, setToken] = useState('')

  async function submit(e) {
    e.preventDefault()
    try {
      await axios.post(loginApi, {
        email, password,token
      },
        {
          credentials: "include",
          headers: "application/json"
        }).then(res => {

          console.log("Inside login functon", res);
          // setToken(res.data)
          localStorage.setItem("LoginData value", res.data.brandLogo)
          localStorage.setItem("brandName", res.data.brandName)
          localStorage.setItem("userId", email)
          localStorage.getItem("userId")
          // localStorage.setItem("secondaryColor",res.data.secondaryColor)

          if (res.status === 200) {
            toast.success("Login Successfully");
            setTimeout(delay, 2000)
            function delay() {
              history("/dashboard");
            }

          } else if (res.status === 204) {
            toast.error("! Oops Missing Fields");
          }
          else if (res.status === 400) {
            alert("Not Val")
          }
        }).catch(e => {
          toast.error("Invalid Credentials");
          console.log(e);
        })
    } catch (e) {
      console.log(e);
    }
  }
  
  const session = () => {
    fetch(sessionApi)
    .then((res) => res.json())
    .then((res) => {
      setToken(res.csrfToken);
        console.log("---------ressssssssssss-----))))", res.csrfToken);
      });
    };
    
    useEffect(() => {
      session();
    },[]);
    
  const brandName = localStorage.getItem('brandName')
  const brandLogo = localStorage.getItem('LoginData value')

  return (
    <>
      <div>
        <ToastContainer position="top-center" autoClose={1000} />
      </div>
      <div className='mainDivLogin'>
        <div className='iamgeLogin'> <img src='./login.png' width="150%" alt='login_iamge' /></div>
        <div className='rightPartLogin'>
          <div className='innerRightPartLogin'>
            <div className="brandLogo"> <img src={brandLogo} alt="brandLogo" /></div>
            <div className='innerHeading'>
              <h3 className='welcome-head'> Welcome Back !</h3>
              <p className='sign-para'>Sign in to continue to {brandName}.</p>
            </div>

            <div className='innerFields mt-3'>
              <form className="p-5 formSection" action="Post" >
                <input type="hidden" value={token} name="token" onChange={(e) => { setToken(e.target.value) }} />
                <label> Username</label>
                <input type='email' className='form-control mt-2 handleArea' placeholder='enter your email' name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} required />
                <label className='mt-4'> Password</label>

                <input type='password' className='form-control mt-2 handleArea' placeholder='enter your password' name="password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                <div className='mt-3'>
                </div>
                <br />
                <Button className='loginBtn handleArea' onClick={submit} >Login</Button>
              </form>
            </div>
            <div className="b-0">
              <div className="d-flex"> <h6>  Powered by LocoWiz</h6> </div> </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default Login


