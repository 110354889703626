import React, { useState, useEffect } from "react"
import "../CSS/AllChat.css"
import "../CSS/Chat.css"
// import { Link, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { retrieveThreadsApi } from './ApiConfiguration';
import { BsHourglassSplit, BsPinAngle } from "react-icons/bs"

function AllChat() {
  // var convid = '';
  const [post, getPost] = useState([]);
  // const [searchParams, setSearchParams] = useSearchParams();
 
  const fetchPost = () => {
    fetch(retrieveThreadsApi).then((res) => res.json()).then((res) => {
      console.log("Respnose form api:", res);
      getPost(res.threads);
    })
  }
  useEffect(() => {
    fetchPost()
  }, [post])

  const logo = localStorage.getItem('LoginData value')
  //console.log("Conversation Id from URL:", searchParams.get("conversationId"));

  return (
    <>
      <div className='chatmain2'>
        <div className='logo-search'>
          <div className='company-logo'> <img src={logo} alt='brand_logo' /> </div>
        </div>

        <div className='chat-message-list2'>
          {post.map((item, i) => {
            var userName = item.displayName.toLowerCase();
            var splitUserName = userName.split(' ')
            var userProfileImage = splitUserName[0].charAt(0).toUpperCase();

            console.log(userProfileImage);
            console.log(userName);
            // convid = item.conversationId

            return <div key={i}>

              <Link to={{ pathname: '/userchat', search: `?conversationId=${item.conversationId}&state=${item.state}` }}
                style={{ textDecoration: "none", textTransform: "capitalize" }}>

                <div style={{ display: "flex", justifyContent: "space-between", padding: "10px", color: "black" }} key={i}>
                  <div className="d-flex gap-1" style={{ alignItems: "center" }}>
                    {
                      item.state === "Bot" ? <div className="profilePictureAll"> {userProfileImage} </div> : item.state === "Live Agent" ? <div className="profilePicture2All"> {userProfileImage} </div> : <div className="profilePicture3All"> {userProfileImage}
                      </div>
                    }
                    <div>
                      {item.displayName}
                      {item.state === "Queued" ? <div style={{ fontSize: "13px", color: "red" }}>  Requested   <BsHourglassSplit /> ( {item.lastUpdated} )</div> : null}
                    </div>
                  </div>
                  <div> <FcGoogle style={{ alignItems: "center" }}/></div>
                </div>
              </Link>

              <div className="time_lastMessage2">
                <div className="lastMessage2"> {item.lastMessageText} </div>
                <div className="time2"> {item.lastUpdated} </div>
              </div>

              {
                !item.storeName ? <div style={{ fontSize: "14px", marginLeft: "15px" }} > <BsPinAngle /> &nbsp;No store available  </div> : <div style={{ fontSize: "14px", marginLeft: "15px" }} > <BsPinAngle /> {item.storeName}</div>
              }
              <hr/>
            </div>

          })}
        </div>

      </div>
    </>
  )
}
export default AllChat