import React from 'react'
import "../CSS/Sidebar.css"
import { TbMessages } from "react-icons/tb"
import { RiLogoutCircleLine } from "react-icons/ri"
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logoutApi } from './ApiConfiguration';

function SideBar() {
      const history = useNavigate();
      async function submit(e) {
            e.preventDefault()
            try {
                  await axios.get(logoutApi, { headers: "application/json" }).then(res => {
                        if (res.status === 200) {
                              toast.success("Logout Successfully");
                              setTimeout(delay, 2000)
                              function delay() {
                                    history("/");
                              }
                        } else {
                              toast.error("unable to Logout try again later");
                        }

                  }).catch(e => {
                        toast.error("unable to Logout");
                        console.log(e);
                  })
            } catch (e) {

                  console.log(e);
            }

      }
      
      return (
            <>
               <div> <ToastContainer position="top-center" autoClose={1000} /> </div>
                  <div className='sidebar'>
                        <div className='text-secondary first-Top'>
                              <img src='./white-logo.png' width="100%" alt="LocoWiz_logo" />
                              <ul className='sidebar-data' >
                                    <li >
                                          <Link to='/dashboard' className='text-secondary'>  <TbMessages className='profile' /></Link>
                                    </li>

                                    <li>
                                          <RiLogoutCircleLine onClick={submit} className='profile' />
                                    </li>
                              </ul>
                        </div>
                  </div>
            </>
      )
}

export default SideBar
