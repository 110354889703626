import React, { useState, useEffect } from "react"
import "../CSS/Messages.css"
import { BiArrowBack } from "react-icons/bi"
import { useSearchParams } from "react-router-dom";
import { MdSend } from "react-icons/md";
import { useNavigate } from "react-router-dom"
import { retrieveMessagesApi, sendMessagesApi, leaveConversationApi, joinConversationApi } from "./ApiConfiguration"
import ScrollableFeed from 'react-scrollable-feed'
import { Link } from 'react-router-dom'

function Messages() {
    const [searchParams] = useSearchParams();
    const [post, setPost] = useState([]);
    const [message, setMessage] = useState([]);

    let conversationId = searchParams.get("conversationId");
    const state = searchParams.get("state")
    const bottomRef = React.useRef(null);

    console.log("StateStateStateState", state);

    // Handle Input
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setMessage({ ...message, [name]: value });
    };

    const gotoHome = useNavigate();
    const appendData = (path) => {
        gotoHome(path);
    }

    // Handle Submit
    const sendAgentMessage = (event) => {
        event.preventDefault();
        var msg = document.getElementById('messageSend').value
        var finalmsg = msg.replace(/ /g, '')


        if (finalmsg != '') {
            fetch(sendMessagesApi, {
                method: "POST",
                body: JSON.stringify({
                    "conversationId": conversationId,
                    "message": message.message
                }),

                headers: {
                    "Content-type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    event.target.reset()
                })
                .catch((err) => {
                    console.log(err.message);
                });

            setMessage('');
        }
        var inputValue = document.getElementById('messageSend');
        inputValue.value = '';
    };

    const fetchPost = () => {
        fetch(retrieveMessagesApi + "?conversationId=" + conversationId)
            .then((res) => res.json())
            .then((res) => {

                setPost(res.messages);

                console.log("--------------))))", res.messages);
            });

    };
    // Join Conversation
    const joinConversation = () => {
        fetch(joinConversationApi, {
            method: 'POST',
            body: JSON.stringify({
                conversationId
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    // Leave Conversation
    const leaveConversation = () => {
        appendData(`/userchat?conversationId=${conversationId}`);

        fetch(leaveConversationApi, {
            method: 'POST',
            body: JSON.stringify({
                conversationId
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            })
            .catch((err) => {
                console.log(err.message);
            });

    }

    useEffect(() => {
        fetchPost();
    }, [post]);

    useEffect(() => {
        bottomRef.current?.scrollIntoView();

    }, [message]);

    return (
        <>
            <div className='backgroundImageM'>
                <header>
                    <div className="main1">
                        <div className="d-flex gap-3">
                            <Link to='/dashboard' className='text-secondary'> <div className="main2"><BiArrowBack className="backarrow" /></div> </Link>
                            <div className="profilePictureMessage">
                                {

                                    post.map((item, i) => {
                                        var userName = item.displayName.toLowerCase();
                                        var splitUserName = userName.split(' ')
                                        var userProfileImage = splitUserName[0].charAt(0).toUpperCase()
                                        return <div key={i}>  {userProfileImage[i]} </div>
                                    })
                                }
                            </div>

                            <div className="main2">
                                <h6 className="user-name-header"> {post.length && post[0].displayName}</h6>
                                <span className="activeshow" > Active</span>
                            </div>
                        </div>

                        <div className="d-flex gap-3">
                            {
                                state === "Live Agent" ? <Link to={{ pathname: '/userchat', search: `?conversationId=${conversationId}&state=Bot` }}>   <button type="button" onClick={leaveConversation} className="btn  btn-outline-danger">Leave Chat</button> </Link> : <Link to={{ pathname: '/userchat', search: `?conversationId=${conversationId}&state=Live Agent` }}><button type="button" onClick={joinConversation} className="btn btn-outline-primary">Join Chat</button>
                                </Link>
                            }
                        </div>
                    </div>
                </header>

                <div id="messageBottom">
                    <ScrollableFeed>
                        {
                            post.map((item, i) => {
                                var userName = item.displayName.toLowerCase();
                                var splitUserName = userName.split(' ')
                                var userProfileImage = splitUserName[0].charAt(0).toUpperCase();
                                let isLink = /(https?:\/\/[^\s]+)/.test(item.messageText);
                                // let isMedia = (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(item.messageText)
                                let newval = [];
                                var url = '';
                                var msg = '';
                                var kol = [];
                                newval = item.messageText.split(' https');
                                if (newval[1]) {
                                    var kil = newval[1].split(' ');
                                    url = 'https' + kil[0];
                                    msg = newval[0];
                                } else {
                                    kol = newval[0].split(' ');
                                    if (kol[1]) {
                                        msg = kol[1];
                                    } else {
                                        isLink = /(https?:\/\/[^\s]+)/.test(kol[0])
                                        isLink ? url = kol[0] : msg = kol[0];
                                    }
                                }

                                return (
                                    <div className="mx-3" key={i} >


                                        {
                                            item.userType === "User" ?

                                                <div>

                                                    <div className="d-flex">
                                                        <div className="profilePictureMessage"> {userProfileImage} </div>
                                                        {
                                                            url ? <div className="message-main  text-start message-main m-2 ">
                                                                <span > {msg} <a className="text-primary" target="_blank" rel="noopener noreferrer" href={url}>{url}</a> </span>
                                                            </div> :
                                                                <p className="message-main m-2 "> {item.messageText}</p>
                                                        }
                                                    </div>

                                                    <span className="myClasstime2">{item.createdDate}</span>
                                                </div>
                                                :
                                                <div className="myClass">
                                                    {

                                                        url ? <div className="message-body m-2">
                                                            <span > {msg} <a className="text-primary" target="_blank" rel="noopener noreferrer" href={url}>{url}</a> </span>
                                                        </div> :
                                                            <p className="message-body"> {item.messageText}</p>
                                                    }
                                                    <span className="myClasstime">{item.createdDate}</span>
                                                </div>
                                        }
                                    </div>

                                )
                            })}

                        {
                            state === "Queued" ? <div className="text-danger text-center opacity-75"> User is requested to connect with live agent</div> : null
                        }
  
                    </ScrollableFeed>
                    <div ref={bottomRef} />
                </div>

                <footer>
                    <form onSubmit={sendAgentMessage} className="footer-form">
                        <div className="d-flex gap-2 justify-content-center">
                            <div className="chat-box">
                                {state === "Live Agent" ? <input type="text" id="messageSend" name="message" value={message.name} onChange={handleInputChange} placeholder="type here...." className="form-control" autoComplete="off" /> : <input type="text" id="messageSend" name="message" value={message.name} onChange={handleInputChange} placeholder="type here...." className="form-control" autoComplete="off" style={{ width: "48vw", display: "none" }} />
                                }
                            </div>
                            {
                                state === "Live Agent" ? <button type="submit" className="sendMessage-btn" ><MdSend className="md-inner" /></button> : <button type="submit" style={{ display: "none" }}  ><MdSend /></button>
                            }
                        </div>
                    </form>
                </footer>
            </div>
        </>
    )
}

export default Messages
