import React from 'react'
import SideBar from './SideBar'
import Chat from './Chat'
import RightMsgs from './RightMsgs'
import SideBarBottom from './SideBarBottom'
import "../CSS/DashBoard.css"
function Dashboard() {
  
  return (
    <>  
    <div  className='all-in-one'>
      <SideBar/>
      <Chat/>
      <RightMsgs/>
    <SideBarBottom/>
    </div>
    </>
  )
}

export default Dashboard
